import type { PropsWithChildren } from "react"
import React, { createContext, useContext } from "react"

import type {
    CurrentUserFieldsFragment,
    MinimalOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import { useAuth } from "~utils/hooks/use-auth"
import { TEAM_MEMBER_ACT_AS_KEY } from "~config/constants"
import { useMyOutfitters } from "~utils/hooks/use-outfitter"
import { useUser as useApiUser } from "~utils/hooks/use-user"

interface IUserContext {
    user: CurrentUserFieldsFragment | null | undefined
    isAdmin: boolean
    isLoading: boolean
    isOutfitter: boolean
    isImpersonating: boolean
    isOwner: boolean
    outfitters: MinimalOutfitterFieldsFragment[] | undefined
    isOutfittersLoading: boolean
}

export const UserContext = createContext<IUserContext>({
    user: null,
    isAdmin: false,
    isLoading: false,
    isOutfitter: false,
    isImpersonating: false,
    isOwner: false,
    outfitters: [],
    isOutfittersLoading: false,
})

export function UserProvider({ children }: Readonly<PropsWithChildren>) {
    const value = useUserValues()

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export function useUser(): IUserContext {
    return useContext(UserContext)
}

// Not intended for use outside of UserProvider.
function useUserValues(): IUserContext {
    const { isLoading: isAuthLoading, authUser } = useAuth()

    const { isLoading: isUserLoading, user } = useApiUser()
    const { outfitters, isLoading: isOutfittersLoading } = useMyOutfitters()

    const isAdmin = Boolean(user?.is_admin)
    const isOutfitter = Boolean(user?.is_team_member)
    const isOwner = Boolean(user?.is_owner) || isAdmin
    const isImpersonating = !!authUser?.[TEAM_MEMBER_ACT_AS_KEY]

    const isLoading = isAuthLoading || isUserLoading

    return {
        user,
        isAdmin,
        isLoading,
        isOutfitter,
        isImpersonating,
        isOwner,
        outfitters,
        isOutfittersLoading,
    }
}
