import React from "react"
import { Icon } from "@chakra-ui/icons"
import { Link } from "@chakra-ui/react"
import { MenuItem } from "@chakra-ui/menu"
import { SiStripe } from "react-icons/si"
import { Spacer, Text } from "@chakra-ui/layout"

import useStripeAccountLoginLink from "~utils/hooks/use-stripe-account-login-link"

export default function StripeAccountButton() {
    const { stripeAccountLoginLink } = useStripeAccountLoginLink()

    return (
        <Link
            href={stripeAccountLoginLink}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
        >
            <MenuItem>
                <Text>Stripe dashboard</Text>
                <Spacer />
                <Icon as={SiStripe} />
            </MenuItem>
        </Link>
    )
}
