import type { PropsWithChildren } from "react"
import React from "react"
import { navigate } from "gatsby"
import { Auth0Provider } from "@auth0/auth0-react"

export default function AuthProvider({
    children,
}: Readonly<PropsWithChildren>) {
    const redirectUri =
        typeof window !== "undefined" ? window.location.origin : ""

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN || ""}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID || ""}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: process.env.GATSBY_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={(appState) => {
                const returnTo = appState
                    ? appState.returnTo
                    : window.location.pathname
                void navigate(returnTo || "", { replace: true })
            }}
        >
            {children}
        </Auth0Provider>
    )
}
