import { useStripeAccountLoginLinkQuery } from "~graphql/generated/graphql"
import { useAuth } from "~utils/hooks/use-auth"

export default function useStripeAccountLoginLink() {
    const { isAuthenticated } = useAuth()

    const {
        data,
        loading: isLoading,
        error,
    } = useStripeAccountLoginLinkQuery({ skip: !isAuthenticated })

    const stripeAccountLoginLink = data?.stripeAccountLoginLink ?? null

    return { stripeAccountLoginLink, isLoading, error }
}
