import React, { useContext, useState, useEffect, useRef } from "react"
import {
    Box,
    Icon,
    Text,
    Button,
    Center,
    Divider,
    Tooltip,
    Collapse,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    useBoolean,
    useColorModeValue,
    AspectRatio,
    Progress,
    useTheme,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import type { IconType } from "react-icons"
import { useMatch } from "@reach/router"
import { BsChevronRight } from "react-icons/bs"

import type { IMenuItem } from "~components/shared/sidebar/items"
import { items, addOutfittersToItems } from "~components/shared/sidebar/items"
import { UserContext } from "~config/context-providers/user-provider"
import { usePermissions } from "~components/shared/permissions/permissions-provider"
import { useOutfittersByOwnerWithListingsQuery } from "~graphql/generated/graphql"
import type { CustomTheme } from "src/@chakra-ui/gatsby-plugin/theme"
import Image from "~components/shared/image"
import SidebarIcon from "~components/shared/sidebar/sidebar-icon"

type SidebarContentProps = {
    readonly isCollapsed: boolean
    readonly onSelect?: () => void
}

function SidebarContent({
    isCollapsed,
    onSelect,
}: SidebarContentProps): React.ReactElement {
    const { isAdmin, user } = useContext(UserContext)
    const matchAssetsItem = useMatch("/admin/assets/*")
    const isAssetsPage = !!matchAssetsItem

    const [selected, setSelected] = useState<string>("")
    const [
        isAssetsCollapsed,
        { toggle: toggleAssets, off: showAssets, on: hideAssets },
    ] = useBoolean(!isAssetsPage)

    const pathMathOutfitter = useMatch("/outfitter/:id")
    const pathMatchListing = useMatch("/manage-listings/:id")

    const [menuItems, setMenuItemsData] = useState<IMenuItem[]>([])

    const [openItemId, setOpenItemId] = useState<number | number[]>([])

    // TODO switch to using useMyOutfitters hook
    const { shouldShowActions } = usePermissions()
    // TODO move away from fetching listings by default
    const { data, loading } = useOutfittersByOwnerWithListingsQuery({
        variables: { id: user?.id || "" },
        skip: !user?.id,
    })

    useEffect(() => {
        const updatedData = addOutfittersToItems({
            outfittersByOwner: data?.outfittersByOwner,
            shouldShowActions,
        })
        setMenuItemsData(updatedData)
    }, [data, shouldShowActions])

    useEffect(() => {
        const listingId = pathMatchListing?.id
        const outfitterId = pathMathOutfitter?.id

        if (listingId) {
            const activeParentIdx = menuItems.findIndex((item) => {
                return item.children
                    ? hasActiveNode(item.children, listingId)
                    : false
            })
            if (activeParentIdx) setOpenItemId(activeParentIdx)
        } else if (outfitterId) {
            const activeParentIdx = menuItems.findIndex(
                (item) => item.id === outfitterId
            )
            if (activeParentIdx) setOpenItemId(activeParentIdx)
        }
    }, [menuItems, pathMatchListing?.id, pathMathOutfitter?.id])

    const handleClick = (label: string, clearAccordion = true) => {
        setSelected(label)
        if (onSelect) onSelect()
        if (clearAccordion) setOpenItemId([])
    }

    useEffect(() => {
        if (isCollapsed) {
            showAssets()
        } else {
            if (!isAssetsPage) hideAssets()
        }
    }, [isCollapsed, showAssets, hideAssets, isAssetsPage])

    return (
        <>
            <Accordion
                py={3}
                allowToggle={false}
                allowMultiple={false}
                index={openItemId}
                onChange={(id) => setOpenItemId(id)}
            >
                {menuItems.map((item) => (
                    <AccordionItem key={item.name} border="none">
                        <SidebarAccordionItem
                            key={item.name}
                            label={item.label}
                            link={item.link}
                            icon={item.icon}
                            image={item.image}
                            isCollapsed={isCollapsed}
                            selected={selected}
                            childrenAmount={item.children?.length}
                            onClick={() => handleClick(item.label, false)}
                        />
                        <AccordionPanel sx={{ padding: "0" }}>
                            <Box>
                                {item.children
                                    ? item.children?.map((listing) => (
                                          <SidebarItem
                                              key={listing.name}
                                              label={listing.label}
                                              link={listing.link}
                                              isCollapsed={isCollapsed}
                                              selected={selected}
                                              id={listing.name}
                                              isChild
                                              onClick={() =>
                                                  handleClick(
                                                      listing.label,
                                                      false
                                                  )
                                              }
                                          />
                                      ))
                                    : null}
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
            {loading && (
                <Progress height="1px" isIndeterminate colorScheme="orange" />
            )}
            {isAdmin && (
                <>
                    <Divider />
                    <Box py={3}>
                        <Collapse in={!isCollapsed}>
                            <Button
                                w="100%"
                                variant="ghost"
                                borderRadius="none"
                                justifyContent="flex-start"
                                paddingLeft={7}
                                fontWeight={700}
                                onClick={toggleAssets}
                                color={isAssetsPage ? "brand.400" : "initial"}
                                rightIcon={
                                    <motion.div
                                        initial={{ rotate: 0 }}
                                        animate={{
                                            rotate: isAssetsCollapsed ? 90 : 0,
                                        }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 260,
                                            damping: 15,
                                        }}
                                    >
                                        <BsChevronRight
                                            fontSize="12px"
                                            strokeWidth="2px"
                                        />
                                    </motion.div>
                                }
                            >
                                Assets
                            </Button>
                        </Collapse>
                        <Collapse in={!isAssetsCollapsed}>
                            {items.assets.map((item) => (
                                <SidebarItem
                                    key={item.name}
                                    label={item.label}
                                    link={item.link}
                                    icon={item.icon}
                                    selected={selected}
                                    isCollapsed={isCollapsed}
                                    onClick={() => handleClick(item.label)}
                                />
                            ))}
                        </Collapse>
                    </Box>
                    <Divider />
                </>
            )}
        </>
    )
}

function hasActiveNode(nodes: IMenuItem[], targetId: string): boolean {
    return nodes.some((node) => node.link.includes(targetId))
}

interface SharedSidebarItemProps {
    readonly label: string
    readonly link: string
    readonly icon?: IconType
    readonly isCollapsed: boolean
    readonly selected: string
    readonly id?: string
    readonly onClick: () => void
}

interface SidebarItemProps extends SharedSidebarItemProps {
    readonly isChild?: boolean
}

function SidebarItem({
    label,
    link,
    icon,
    isCollapsed,
    selected,
    id,
    isChild,
    onClick,
}: SidebarItemProps) {
    const match = useMatch(link === "/" ? link : `${link}`) || selected === id
    const color = useColorModeValue("gray.900", "white")
    const bgColor = useColorModeValue("gray.100", "gray.800")

    return (
        <Link to={link} onClick={onClick}>
            <Tooltip
                hasArrow
                arrowSize={8}
                label={label}
                isDisabled={label.length < 20}
                placement="right"
            >
                <Box
                    py={1}
                    pr={2}
                    pl={isChild && !isCollapsed ? 6 : 2}
                    w="100%"
                    display="flex"
                    position="relative"
                    alignItems="center"
                    userSelect="none"
                    justifyContent={isCollapsed ? "center" : "flex-start"}
                    bgColor={match ? bgColor : "initial"}
                    transition="all .2s"
                    _hover={{ bgColor }}
                >
                    {match && (
                        <Box
                            w="4px"
                            h="100%"
                            left={0}
                            position="absolute"
                            bgColor="brand.400"
                        />
                    )}
                    <SidebarIcon
                        icon={icon}
                        label={label}
                        isHighlighted={Boolean(match)}
                    />
                    <Text
                        pt="2px"
                        fontSize="14px"
                        fontWeight={match ? 700 : 500}
                        color={match ? "brand.400" : color}
                        width={isCollapsed ? "0px" : "200px"}
                        transition="width .3s"
                        noOfLines={1}
                    >
                        {label}
                    </Text>
                </Box>
            </Tooltip>
        </Link>
    )
}

interface SidebarAccordionItemProps extends SharedSidebarItemProps {
    readonly image?: string
    readonly childrenAmount?: number
}

function SidebarAccordionItem({
    label,
    link,
    icon,
    image,
    isCollapsed,
    selected,
    id,
    childrenAmount,
    onClick,
}: SidebarAccordionItemProps) {
    const styles = useStyleProps()
    const { colors } = useTheme<CustomTheme>()

    const match = useMatch(link === "/" ? link : `${link}/*`) || selected === id
    const color = useColorModeValue("gray.900", "white")
    const bgColor = useColorModeValue("gray.100", "gray.800")
    const iconBgColor = useColorModeValue("gray.50", "gray.900")

    const [infoStatus, setInfoStatus] = useState<boolean>(false)

    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const refValue = ref?.current?.ariaExpanded === "true"
        const condition = !refValue && Boolean(childrenAmount) && !isCollapsed
        setInfoStatus(condition)
    }, [ref?.current?.ariaExpanded, isCollapsed, childrenAmount])

    return (
        <Link to={link} onClick={onClick}>
            <Tooltip
                hasArrow
                arrowSize={8}
                label={label}
                isDisabled={!isCollapsed}
                placement="right"
            >
                <AccordionButton ref={ref} sx={{ padding: "0" }}>
                    <Box
                        flex="1"
                        textAlign="left"
                        py={1}
                        px={2}
                        w="100%"
                        display="flex"
                        position="relative"
                        alignItems="center"
                        justifyContent={
                            isCollapsed ? "center" : "space-between"
                        }
                        bgColor={match ? bgColor : "initial"}
                        transition="all .2s"
                        _hover={{ bgColor }}
                    >
                        {match && (
                            <Box
                                position="absolute"
                                left={0}
                                h="100%"
                                w="4px"
                                bgColor="brand.400"
                            />
                        )}

                        {image ? (
                            <Box mx={3}>
                                <AspectRatio
                                    ratio={1}
                                    w="34px"
                                    overflow="hidden"
                                    rounded="lg"
                                    borderWidth="2px"
                                    borderColor={
                                        match ? "brand.200" : "gray.200"
                                    }
                                >
                                    <Image
                                        w="100%"
                                        h="100%"
                                        bgColor="gray.50"
                                        src={image}
                                        skeletonStyles={styles.imageSkeleton}
                                    />
                                </AspectRatio>
                            </Box>
                        ) : (
                            <Center
                                mx={3}
                                p="8px"
                                borderRadius="lg"
                                bgColor={iconBgColor}
                            >
                                <Icon
                                    h="18px"
                                    w="18px"
                                    as={icon}
                                    color={color}
                                    opacity={match ? 1 : 0.8}
                                />
                            </Center>
                        )}

                        <Text
                            pt="2px"
                            fontSize="14px"
                            fontWeight={match ? 700 : 500}
                            color={match ? "brand.400" : color}
                            width={isCollapsed ? "0px" : "200px"}
                            transition="width .3s"
                            noOfLines={1}
                        >
                            {label}
                        </Text>

                        {infoStatus && (
                            <Center
                                ml={2}
                                minH="20px"
                                minW="20px"
                                rounded="md"
                                fontWeight="bold"
                                bg="gray.200"
                                fontSize="12px"
                                color={colors.custom.gray}
                            >
                                {childrenAmount}
                            </Center>
                        )}
                    </Box>
                </AccordionButton>
            </Tooltip>
        </Link>
    )
}

export default SidebarContent

function useStyleProps() {
    const imageSkeleton = {
        rounded: "md",
    }

    return { imageSkeleton }
}
