import React from "react"
import { FiSettings } from "react-icons/fi"

import { useStripeAccountCheckQuery } from "~graphql/generated/graphql"
import StripeAccountButton from "~components/shared/header/stripe-account-button"
import IconMenuItem from "~components/shared/icon-menu-item"
import { PROFILE_MENU_LABELS } from "~config/constants"
import { getAccountPageUrl } from "~utils/helpers/navigation-helpers"

export default function AccountSettingsSection() {
    const { data, loading } = useStripeAccountCheckQuery({
        fetchPolicy: "network-only",
    })

    const showStripeAccount = !loading && data?.stripeAccountCheck

    return (
        <>
            {showStripeAccount && <StripeAccountButton />}
            <IconMenuItem
                href={getAccountPageUrl()}
                isExternal
                label={PROFILE_MENU_LABELS.ACCOUNT_SETTINGS}
                icon={FiSettings}
            />
        </>
    )
}
