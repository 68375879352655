import { useEffect, useState } from "react"
import { captureException } from "@sentry/react"
import type { AppcuesUserProps } from "@mallardbay/lib-shared-helpers"
import {
    trackingPropsToHash,
    tryWithDelay,
} from "@mallardbay/lib-shared-helpers"

import { useUser } from "~config/context-providers/user-provider"
import { useGetUserTrackingDataQuery } from "~graphql/generated/graphql"
import type { CurrentUserFieldsFragment } from "~graphql/generated/graphql"

const delayMs = 200

export default function useAppcues() {
    const [didInit, setDidInit] = useState(false)
    const { user } = useUser()
    const maxAttempts = 10
    const { isLoading, appcuesProps } = useAppcuesProps()

    useEffect(() => {
        const shouldTry = !didInit && user?.id && !isLoading && appcuesProps
        if (shouldTry) {
            tryWithDelay({
                functionToCall: () =>
                    initAppcues(setDidInit, user, appcuesProps),
                delay: delayMs,
                attemptNumber: 1,
                maxAttempts,
                hasSucceeded: false,
                errorMessage: `Failed to initialize appcues after ${maxAttempts} attempts`,
                captureException,
            })
        }
    }, [didInit, user, isLoading, appcuesProps])
}

function initAppcues(
    setDidInit: (val: boolean) => void,
    user: CurrentUserFieldsFragment,
    props: AppcuesUserProps
) {
    const canInit = window.Appcues
    // Bail if Appcues or the user aren't loaded
    if (!canInit) {
        return false
    }

    window.Appcues.identify(user.id, props)
    setDidInit(true)
    return true
}

function useAppcuesProps() {
    const { loading, error, data } = useGetUserTrackingDataQuery({
        onError: (err) => captureException(err),
    })

    const props = data?.userTrackingData?.props || []

    const propHash = trackingPropsToHash(props)

    return {
        isLoading: loading,
        error,
        appcuesProps: propHash,
    }
}
