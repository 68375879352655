import type { PropsWithChildren } from "react"
import React, { useState, useEffect, useContext } from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import {
    Box,
    Flex,
    Center,
    Divider,
    useBreakpointValue,
} from "@chakra-ui/react"
import { useMatch } from "@reach/router"
import { Script } from "gatsby"

import useAppcues from "~utils/appcues-hooks/use-appcues"
import Header from "~components/shared/header/header"
import Sidebar from "~components/shared/sidebar/sidebar"
import SidebarDrawer from "~components/shared/sidebar/sidebar-drawer"
import ImpersonationBox from "~components/shared/auth/impersonation-box"
import AuthenticatingLoader from "~components/shared/loader/authenticating-loader"
import useOnboardingRedirects from "~utils/hooks/use-onboarding-redirects"
import { UserContext } from "~config/context-providers/user-provider"
import { ROUTES } from "~config/constants"

function Layout({ children }: PropsWithChildren) {
    const { user, isLoading: isLoadingUser } = useContext(UserContext)
    const matchInbox = !!useMatch("/inbox/*")
    const matchOnboardListing = useMatch("/onboard")
    const matchOnboardOutfitter = useMatch("/outfitter/new")
    const matchProgress = useMatch("/listing/:id/progress")
    const matchStep = useMatch("/listing/:id/edit/:step") // TODO: Leave layout our of edit page too
    const matchCreate = useMatch("/listing/create")
    const matchTeamMemberOnboarding = useMatch(ROUTES.TEAM_MEMBER_ONBOARDING)

    const [isOpen, setIsOpen] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(matchInbox)
    const contentWidth = useBreakpointValue({
        base: "100%",
        // account for sidebar width and 1px vertical line
        md: isCollapsed
            ? "calc(100% - 72px - 1px)"
            : "calc(100% - 250px - 1px)",
    })

    useAppcues()

    useEffect(() => {
        if (matchInbox) setIsCollapsed(true)
    }, [matchInbox])

    const { isRedirecting } = useOnboardingRedirects()

    // Prevents the screen from flickering durect redirects.
    if (isRedirecting) return null

    if (
        matchOnboardListing ||
        matchOnboardOutfitter ||
        matchTeamMemberOnboarding
    ) {
        return (
            <Box h="100vh" w="100%">
                {children}
            </Box>
        )
    }

    if (matchProgress || matchStep || matchCreate) {
        return (
            <Box h="auto" w="100%" position="relative">
                {children}
            </Box>
        )
    }

    if (!user || isLoadingUser) return <AuthenticatingLoader />

    return (
        <>
            <Flex position="relative">
                <Box
                    position="sticky"
                    top={0}
                    left={0}
                    display={{ base: "none", md: "flex" }}
                    height="100vh"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Sidebar
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Divider orientation="vertical" />
                </Box>
                <main
                    style={{
                        width: contentWidth,
                        minHeight: "100vh",
                        transition: "width 0.3s",
                        position: "relative",
                    }}
                >
                    <Flex flexDir="column" h="100%">
                        <Header openSidebar={() => setIsOpen(true)} />
                        <Box h="100%" pos="relative">
                            {children}
                        </Box>
                        <Center pos="sticky" bottom={0} zIndex={1}>
                            <ImpersonationBox />
                        </Center>
                    </Flex>
                </main>
            </Flex>
            <SidebarDrawer
                isOpen={isOpen}
                closeSidebar={() => setIsOpen(false)}
            />
            {/* See docs https://studio.appcues.com/settings/installation/guide/overview */}
            <Script type="text/javascript">
                {`window.AppcuesSettings = { enableURLDetection: true };`}
            </Script>
            <Script src="//fast.appcues.com/209636.js"></Script>
        </>
    )
}

export default withAuthenticationRequired(Layout, {
    onRedirecting: () => <AuthenticatingLoader />,
})
