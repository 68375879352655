import { formatInTimeZone } from "date-fns-tz"

import {
    formatDatesRange,
    DEFAULT_TIMEZONE,
    DEFAULT_LONG_DATE_FORMAT,
    DEFAULT_CHECK_IN_FORMAT,
} from "~utils/helpers/date-helpers"
import COPY from "~config/copy-constants"
import type {
    BookingFieldsFragment,
    BookingItemFieldsFragment,
    BookingPreviewFieldsFragment,
    BookingPreviewWithRefundsFieldsFragment,
} from "~graphql/generated/graphql"
import { BookingStatus, ListingType } from "~graphql/generated/graphql"

export const getBookingStatusColorScheme = (
    status: BookingStatus
): string | undefined => {
    switch (status) {
        case BookingStatus.Requested:
            return "orange"
        case BookingStatus.Approved:
            return "green"
        case BookingStatus.Completed:
            return "green"
        case BookingStatus.Canceled:
            return "red"
        case BookingStatus.Denied:
            return "red"
        default:
            return undefined
    }
}

export const convertBookingStatus = (status: BookingStatus): string => {
    switch (status) {
        case BookingStatus.Approved:
            return "Upcoming"
        case BookingStatus.Denied:
            return "Declined"
        case BookingStatus.Canceled:
            return "Cancelled"
        default:
            return status
    }
}

export const getStatusBgColor = (status: BookingStatus): string => {
    switch (status) {
        case BookingStatus.Approved:
            return "green.400"
        case BookingStatus.Completed:
            return "green.400"
        case BookingStatus.Requested:
            return "orange.400"
        default:
            return "red.500"
    }
}

export function getGuestsCountForBooking({
    items,
    isOffsiteBooking,
}: {
    items: BookingItemFieldsFragment[]
    isOffsiteBooking?: boolean | null
}): { [optionName: string]: number } {
    const guestsByOptionName: {
        [optionName: string]: number
    } = {}

    items.forEach((bookingItem) => {
        const optionName = bookingItem.name

        if (!guestsByOptionName[optionName]) guestsByOptionName[optionName] = 0

        // TODO: Remove this once we fix the way we save items for offline bookings
        if (isOffsiteBooking) {
            guestsByOptionName[optionName] = bookingItem.quantity
        } else {
            guestsByOptionName[optionName] += 1
        }
    })

    return guestsByOptionName
}

export const getTotalGuestCountFromBookingItems = (
    items: BookingItemFieldsFragment[]
): number => {
    return items.length
}

export const getBookingTimezone = (
    booking: BookingFieldsFragment | BookingPreviewFieldsFragment
): string => {
    return (
        booking.listing.time_zone ||
        booking.outfitter.time_zone ||
        DEFAULT_TIMEZONE
    )
}

export const formatBookingCheckInTime = (
    booking: BookingFieldsFragment | BookingPreviewFieldsFragment
) => {
    const timeZone = getBookingTimezone(booking)

    return booking.has_lodging
        ? formatInTimeZone(
              booking.check_in_time,
              timeZone,
              DEFAULT_CHECK_IN_FORMAT
          )
        : "None; lodging not included"
}

export const getBookingDatesFromToLabel = ({
    booking,
    dateFormat = DEFAULT_LONG_DATE_FORMAT,
}: {
    booking: BookingFieldsFragment
    dateFormat?: string
}): string => {
    const timeZone =
        booking.listing.time_zone ||
        booking.outfitter.time_zone ||
        DEFAULT_TIMEZONE

    const startDateFormat = formatInTimeZone(
        booking.start_date,
        timeZone,
        dateFormat
    )
    const endDateFormat = formatInTimeZone(
        booking.end_date,
        timeZone,
        dateFormat
    )

    const multiDayLabel = `from ${startDateFormat} to ${endDateFormat}`
    const singleDayLabel = `on ${startDateFormat}`

    return booking.dates.length > 1 ? multiDayLabel : singleDayLabel
}

export const getBookingDatesLabel = (
    booking: BookingFieldsFragment | BookingPreviewFieldsFragment
): string => {
    const timeZone = getBookingTimezone(booking)

    return booking.dates.length
        ? formatDatesRange(booking.start_date, booking.end_date, { timeZone })
        : "Not available"
}

export const getBookingListingType = ({
    booking,
    fallback = "",
}: {
    booking: BookingFieldsFragment | BookingPreviewFieldsFragment
    fallback?: string
}) => {
    const { type } = booking.listing

    switch (type) {
        case ListingType.Hunting:
            return COPY.LISTING_TYPES.HUNTING
        case ListingType.Fishing:
            return COPY.LISTING_TYPES.FISHING
        case ListingType.BlastCast:
            return COPY.LISTING_TYPES.BLAST_CAST
        default:
            return fallback
    }
}

export function isRequestedBookingStatus(booking: BookingFieldsFragment) {
    return booking.status === BookingStatus.Requested
}

export function isCompletedBookingStatus(booking: BookingFieldsFragment) {
    return booking.status === BookingStatus.Completed
}

export function isApprovedBookingStatus(booking: BookingFieldsFragment) {
    return booking.status === BookingStatus.Approved
}

export function filterBookingsByStatus({
    bookings,
    status,
}: {
    bookings: BookingPreviewWithRefundsFieldsFragment[]
    status: BookingStatus
}) {
    return bookings.filter((booking) => booking.status === status)
}
